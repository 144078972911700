import React from "react"
import {Icon, Segment, Divider, Table} from "semantic-ui-react"
import {Link} from "gatsby"
import Constants from "../Shared/Constants";
import 'semantic-ui-css/semantic.min.css'

class MasksTable extends React.Component {
    render() {
        let sections = []

        function renderSubsection(supplierDocumentType) {
            let subSections = []
            for (const subSection in supplierDocumentType) {
                let currentDocumentsStatus = supplierDocumentType[subSection].currentDocumentsStatus
                const currentStatusUploadable = undefined !== currentDocumentsStatus && 0 < currentDocumentsStatus.length
                subSections.push(
                    <Table.Row key={supplierDocumentType[subSection].id}>
                        <Table.Cell width={1}>
                            {
                                supplierDocumentType[subSection].isUploadable ?
                                    <Link to={`/app/maskEdit/${supplierDocumentType[subSection].externalId}`}>
                                        <Icon name="cloud upload"/>
                                    </Link>
                                    :
                                    null
                            }
                        </Table.Cell>
                        <Table.Cell width={1}>
                            {
                                supplierDocumentType[subSection].hasTemplate ?
                                    <a href={Constants.PATH_PREFIX + "/" + supplierDocumentType[subSection].assetName}>
                                        <Icon name="file pdf outline"/>
                                    </a>
                                    :
                                    null
                            }
                        </Table.Cell>
                        <Table.Cell width={4}>
                            {supplierDocumentType[subSection].description}
                        </Table.Cell>
                        {
                            currentStatusUploadable ?
                                <>
                                    <Table.Cell width={3}>
                                        {currentDocumentsStatus[0].documentStatus.description}
                                    </Table.Cell>
                                    <Table.Cell width={3}>
                                        {currentDocumentsStatus[0].description}
                                    </Table.Cell>
                                </>
                                :
                                <>
                                    <Table.Cell width={3}/>
                                    <Table.Cell width={3}/>
                                </>
                        }
                        {
                            currentStatusUploadable && currentDocumentsStatus[0].supplierUploadedDocument ?
                                <>
                                    <Table.Cell width={3}>
                                        {currentDocumentsStatus[0].expirationDate}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                        <a href={Constants.UPLOADED_FILE_PATH + "/" + currentDocumentsStatus[0].supplierUploadedDocument.filePath}>
                                            <Icon name="download"/>
                                        </a>
                                    </Table.Cell>
                                </>
                                :
                                <>
                                    <Table.Cell width={3}/>
                                    <Table.Cell width={1}/>
                                </>
                        }
                    </Table.Row>,
                )
            }

            return subSections
        }

        function renderSection(section) {
            return (
                <Segment color={"red"} size={"big"} raised key={section.id}>
                    <Icon name="archive" size={"large"}/>
                    {section.description}
                    <Divider/>
                    <Table>
                        <Table.Header>
                            <Table.Row key={section.id}>
                                <Table.HeaderCell width={1}>Carica</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Modulo</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Tipo Documento</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Stato attuale</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Ulteriori informazioni</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Data di scadenza</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Scarica</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {renderSubsection(section.supplierDocumentType)}
                        </Table.Body>
                    </Table>
                    <Segment>
                        <Icon name="mail outline" size={"large"}/>
                        Per eventuali informazioni/chiarimenti inviare una email a &nbsp;
                        <a href="mailto:fornitori@firenzefiera.it">
                            fornitori@firenzefiera.it
                        </a>
                        .
                    </Segment>
                    {section.isUploadable ?
                        <Segment>
                            <Icon name="question circle outline" size={"large"}/>
                            In caso di impossibilità a caricare il documento, inserire in sostituzione dichiarazione
                            firmata precisando il perché di tale impossibilità.
                        </Segment>
                        :
                        null
                    }
                </Segment>
            )
        }

        for (const index of this.props.data) {
            const toBeRendered = index.supplierDocumentType.length !== 0
            if (toBeRendered) {
                sections.push(
                    renderSection(index),
                )
            }
        }

        return (
            <Segment.Group>
                {sections}
            </Segment.Group>
        )
    }
}

export default MasksTable