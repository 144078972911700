import React from "react"
import RestManager from "../Shared/RestManager"
import Constants from "../Shared/Constants"
import CurrentDocumentStatusTable from "./CurrentDocumentStatusTable"
import { Container } from "semantic-ui-react"
import LocalStorageManager from "../Shared/LocalStorageManager";

class CurrentDocumentStatus extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            isFetching: false,
            masks: [],
        }
    }

    username = LocalStorageManager.getKey("username")

    componentDidMount () {
        this.fetchMasks()
    }

    fetchMasks () {
        this.setState({ ...this.state, isFetching: true })
        RestManager.get(Constants.CURRENT_DOCUMENT_STATUSES, {'supplier.email':this.username})
            .then(result => {
                this.setState({ masks: result.data })
                this.setState({ ...this.state, isFetching: false })
            })
            .catch(error => {
                this.setState({ ...this.state, isFetching: false })
            })
    }

    render () {
        return (
            <>
                <Container as={"h2"} textAlign={"center"}>
                    Current document status
                </Container>
                <p>
                    {this.state.isFetching ? "Caricamento..." : ""}
                </p>
                <CurrentDocumentStatusTable data={this.state.masks}/>
            </>
        )
    }

}

export default CurrentDocumentStatus