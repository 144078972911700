import React from "react"
import { Table, Icon } from "semantic-ui-react"
import Constants from "../Shared/Constants"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import RestManager from "../Shared/RestManager"
import { toast } from "react-toastify"

class UploadedMasksTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showDeleteConfirm: false }
  }

  confirmDeleteDocument (id) {
    confirmAlert({
      title: "Cancellazione docuemnto",
      message: "Confermi la cancellazione di questo documento ?",
      buttons: [
        {
          label: "Si",
          onClick: () => this.deleteDocument(id),
        },
        {
          label: "No",
          onClick: () => {
          },
        },
      ],
    })
  }

  deleteDocument (id) {
    const deleteUri = Constants.SUPPLIER_UPLOAD_DOCUMENTS_ENDPOINT + "/" + id

    RestManager.delete(deleteUri, {})
      .then(
        response => {
          toast.success("File cancellato con successo")

          setTimeout(() => {
            this.props.fetchUploadedMasks()
          }, 3500)
        },
      ).catch(
      error => {
        toast.error("Errore nella cancellazione del file")
      },
    )
  }

  render () {
    let items = []

    for (const index of this.props.data) {
      items.push(
        <Table.Row key={index.id}>
          <Table.Cell>
            <a href={Constants.UPLOADED_FILE_PATH + "/" + index.filePath}>
              <Icon name="file alternate outline"/>
              {index.originalFileName}
            </a>
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            {index.supplierDocumentType.name}
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            <Icon name="trash alternate outline" onClick={() => this.confirmDeleteDocument(index.id)}/>
          </Table.Cell>
        </Table.Row>,
      )
    }

    return (
      <Table color={"red"} selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>File</Table.HeaderCell>
            <Table.HeaderCell textAlign={"right"}>Tipo</Table.HeaderCell>
            <Table.HeaderCell textAlign={"right"}><Icon name="edit outline"/></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items}
        </Table.Body>
      </Table>
    )
  }
}

export default UploadedMasksTable