import React from "react"
import { Container, Form, Button, Icon } from "semantic-ui-react"
import { navigate } from "gatsby"
import LocalStorageManager from "./Shared/LocalStorageManager"
import { toast } from "react-toastify"
import RestManager from "./Shared/RestManager"
import Constants from "./Shared/Constants"

class Profile extends React.Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }

  username = LocalStorageManager.getKey("username")

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validateForm = () => {
    const { newPassword, confirmNewPassword } = this.state

    if (confirmNewPassword !== newPassword) {
      toast.error("Le password non coincidono")
      return false
    }

    return true
  }

  handleSubmit = event => {
    event.preventDefault()

    if (this.validateForm()) {
      const username = LocalStorageManager.getKey("username")

      RestManager.post(Constants.USERPROFILE_ENDPOINT,
        {
          "oldPassword": this.state.oldPassword,
          "newPassword": this.state.newPassword,
          "username": username,
        })
        .then(response => {
          if(200 === response.data.status) {
            toast.success(response.data.message)
            setTimeout(() => {
              navigate("/app/home")
            }, 3500)
          } else {
            toast.error(response.data.message)
          }


        }).catch(errors => {
        toast.error("Errore nella modifica della password")
      })
    } else {
      return null
    }
  }

  render () {
    return (
      <>
        <Container as={"h2"} textAlign={"center"}>
          <Icon name={"user outline"} size={"large"}/>
          Profilo
        </Container>
        <Container as={"h2"} textAlign={"left"}>Utente: {this.username}</Container>
        <Container textAlign={"left"}>Cambio password</Container>
        <Container textAlign={"left"}>
          <Form
            onSubmit={event => {
              this.handleSubmit(event)
            }}
          >
            <Form.Field>
              <label htmlFor="oldPassword">Vecchia password</label>
              <input id="oldPassword"
                     name="oldPassword"
                     type="password"
                     placeholder='Vecchia password'
                     onChange={this.handleUpdate}/>
            </Form.Field>
            <Form.Field>
              <label htmlFor="newPassword">Nuova password</label>
              <input id="newPassword"
                     name="newPassword"
                     type="password"
                     placeholder='Nuova password'
                     onChange={this.handleUpdate}/>
            </Form.Field>
            <Form.Field>
              <label htmlFor="confirmNewPassword">Ripeti nuova password</label>
              <input
                placeholder='Ripeti nuova password'
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                onChange={this.handleUpdate}
              />
            </Form.Field>
            <Button type='submit'>Invia</Button>
          </Form>
        </Container>
      </>
    )
  }
}

export default Profile