import RestManager from "./RestManager"
import Constants from "./Constants"

class SupplierValidator {
  async validate (vatNumber, email, name, checkedSupplyTypes, checkedCommodityTypes) {
    let validationErrors = []

    /**
     * Remote validations.
     * @type {R}
     */
    const remoteValidationErrors = await RestManager.get(Constants.VALIDATE_SUPPLIER_ENDPOINT,
      {
        "vatNumber": vatNumber,
        "email": email,
      })

    Object.keys(remoteValidationErrors.data).forEach(function(item) {
      validationErrors.push(remoteValidationErrors.data[item])
    })

    /*
     * Client side validations.
     */
    if (!/^[0-9]{11}$/.test(vatNumber)) {
      validationErrors.push("Partita Iva non valida")
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
      validationErrors.push("Email non valida")
    }

    if (0 === name.length) {
      validationErrors.push("Ragione sociale obbligatoria")
    }

    if (0 === checkedSupplyTypes.length){
      validationErrors.push("Seleziona almeno una tipologia di forniture")
    }

    if (0 === checkedCommodityTypes.length){
      validationErrors.push("Seleziona almeno una classe merceologica")
    }

    return validationErrors
  }
}

export default SupplierValidator