import React from "react"
import RestManager from "./Shared/RestManager"
import { Container, Form, Button, Icon } from "semantic-ui-react"
import Constants from "./Shared/Constants"
import { navigate } from "gatsby-link"
import { toast } from "react-toastify"

class RetrievePassword extends React.Component {
  state = {
    username: "",
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    RestManager.post(Constants.RETRIEVE_PASSWORD_ENDPOINT,
      {
        "username": this.state.username,
      })
      .then(response => {
          toast.success("Grazie, riceverai una mail per confermare completare il cambio password")

          setTimeout(() => {
            navigate("/app/login")
          }, 3500)
        },
      ).catch(errors => {
      console.log(errors)
    })
  }

  render () {
    return (
      <>
        <Container as={"h2"} textAlign={"center"}>
          <Icon name={"user outline"} size={"large"}/>
          Profilo
        </Container>
        <Container textAlign={"left"}>Recupera password</Container>
        <Container textAlign={"left"}>
          <Form
            onSubmit={event => {
              this.handleSubmit(event)
            }}
          >
            <Form.Field>
              <label htmlFor="username">Inserire il nome utente</label>
              <input id="username"
                     name="username"
                     type="text"
                     placeholder="Username"
                     onChange={this.handleUpdate}/>
            </Form.Field>
            <Button type='submit'>Invia</Button>
          </Form>
        </Container>
      </>
    )
  }
}

export default RetrievePassword