import React from "react"
import { Icon, Segment, Grid, Divider} from "semantic-ui-react"
import Constants from "../Shared/Constants";

class CurrentDocumentStatusTable extends React.Component {
    render () {
        let documents = []

        for (const index of this.props.data) {
            documents.push(
                <Grid.Row>
                    <Grid.Column width={7}>
                        {index.supplierDocumentType.name}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {index.documentStatus.description}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {index.description}
                    </Grid.Column>
                    {index.supplierUploadedDocument ?
                        <Grid.Column width={1}>
                            <a href={Constants.UPLOADED_FILE_PATH +"/"+ index.supplierUploadedDocument.filePath}>
                                <Icon name="file pdf outline"/>
                            </a>
                        </Grid.Column>
                        :
                        null
                    }

                </Grid.Row>
                ,
            )
        }

        return (
            <Segment.Group>
                <Segment color={"red"} size={"big"} raised>
                    <Icon name="archive" size={"large"}/>
                    <Divider/>
                    <Grid>
                        {documents}
                    </Grid>
                </Segment>
            </Segment.Group>
        )
    }
}

export default CurrentDocumentStatusTable