import React from "react"
import RestManager from "../Shared/RestManager"
import Constants from "../Shared/Constants"
import { Card, Icon, Container, Button, Input } from "semantic-ui-react"
import LocalStorageManager from "../Shared/LocalStorageManager"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

class MaskEdit extends React.Component {
  maskId = ""

  constructor (props, context) {
    super(props, context)
    this.state = {
      isFetching: false,
      mask: {},
      selectedFile: null,
    }
  }

  componentDidMount () {
    this.loadDocument(this.props.maskId)
  }

  onClickHandler = () => {
    const data = new FormData()
    data.append("file", this.state.selectedFile)

    let uploadUri = Constants.SUPPLIER_UPLOAD_DOCUMENTS_ENDPOINT
    uploadUri += "?dct=" + this.props.maskId
    uploadUri += "&usr=" + LocalStorageManager.getKey("username")

    this.setState({ isFetching: true })
    RestManager.post(uploadUri, data)
      .then(response => {
        if (422 === response.data.status) {
          response.data.message.forEach(message => toast.error(message))
        } else if (200 === response.data.status) {
          toast.success(response.data.message)
        } else {
          toast.error("Errore sconosciuto nel caricamento del file")
        }

        this.setState({ isFetching: false })

        setTimeout(() => {
          navigate("/app/masksList")
        }, 3500)
      }).catch(
      error => {
        this.setState({ isFetching: false })

        console.log(error)
      },
    )
  }

  loadDocument (maskId) {
    this.setState({ ...this.state, isFetching: true })
    let maskUri = Constants.SUPPLIER_DOCUMENT_TYPES_ENDPOINT_UPLOAD

    RestManager.get(maskUri, { externalId: maskId })
      .then(result => {
        this.setState({ mask: result.data })
        this.setState({ ...this.state, isFetching: false })
      }).catch(error => {
      this.setState({ ...this.state, isFetching: false })
    })
  }

  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }

  render () {
    return (
      <>
        {
          this.state.isFetching ?
            "Caricamento..."
            :
            <Container as={"h2"} textAlign={"center"}>{this.state.mask.name}
              <Card centered={true}>
                <Card.Content>
                  <Icon name={"cloud upload"} size={"huge"}/>
                </Card.Content>
                <Card.Content>
                  <Input fluid={true} type="file" name="file" onChange={this.onChangeHandler} size={"mini"}/>
                </Card.Content>
                <Card.Content extra>
                  <Button onClick={this.onClickHandler}>Upload</Button>
                </Card.Content>
              </Card>
            </Container>
        }
      </>
    )
  }
}

export default MaskEdit