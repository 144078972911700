import React from "react"
import {Button, Checkbox, Container, Form, Icon, Input, List, Message, TextArea} from "semantic-ui-react"
import RestManager from "./Shared/RestManager"
import {navigate} from "gatsby"
import {toast} from "react-toastify"
import SupplierValidator from "./Shared/SupplierValidator"
import Constants from "./Shared/Constants"
import doc1 from "../assets/documnents/Informativa_GDPR_Albo_Fornitori.pdf"
import doc2 from "../assets/documnents/Regolamento_Albo_Fornitori.pdf"
import CommodityType from "./CommodityType"
import HelpDocuments from "./Shared/HelpDocuments"

class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            vatNumber: "",
            email: "",
            notes: "",
            type1: false,
            type2: false,
            type3: false,
            type4: false,
            type5: false,
            disableRegister: true,
            isFetching: false,
            supplyTypes: [],
            checkedSupplyTypes: [],
            checkedCommodityTypes: [],
        }

        this.toggleRegister = this.toggleRegister.bind(this)
        this.supplierValidator = new SupplierValidator()
    }

    componentDidMount() {
        this.fetchMasks()
    }

    fetchMasks() {
        this.setState({...this.state, isFetching: true})
        RestManager.get(Constants.SUPPLY_TYPE_COMMODITY_ENDPOINT, null)
            .then(result => {
                this.setState({supplyTypes: result.data})
                this.setState({...this.state, isFetching: false})
            })
            .catch(error => {
                this.setState({...this.state, isFetching: false})
            })
    }

    handleUpdate = event => {
        const target = event.target
        const value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        //@todo find a better name detection
        if (name == parseInt(name, 10)) {
            let check = this.state.checkedCommodityTypes

            value ? check.push(name) : check = check.filter(id => id !== name);

            this.setState({
                checkedCommodityTypes: check,
            })
        } else {
            this.setState(state => ({...state, [name]: value}))
        }
    }

    async validateForm() {
        const {vatNumber, email, name, checkedSupplyTypes, checkedCommodityTypes} = this.state
        return await this.supplierValidator.validate(vatNumber, email, name, checkedSupplyTypes, checkedCommodityTypes)
    }

    async handleSubmit(event) {
        event.preventDefault()

        const tempCheckType = []

        if (this.state.type1) {
            tempCheckType.push(1)
        }

        if (this.state.type2) {
            tempCheckType.push(2)
        }

        if (this.state.type3) {
            tempCheckType.push(3)
        }

        if (this.state.type4) {
            tempCheckType.push(4)
        }

        if (this.state.type5) {
            tempCheckType.push(5)
        }

        //@todo use setState
        // this.setState(state => ({...state, checkedSupplyTypes: tempCheckType}))
        this.state.checkedSupplyTypes = tempCheckType
        
        for (const index in this.state.checkedCommodityTypes) {
            for (const type of this.state.supplyTypes) {
                if (type['supplyCommodityTypes'].some(commodityType => commodityType.id == this.state.checkedCommodityTypes[index])) {
                    if (!this.state.checkedSupplyTypes.includes(type['id'])) {
                        this.state.checkedCommodityTypes[index] = undefined
                    }
                }
            }
            if (undefined !== this.state.checkedCommodityTypes[index]) {
                this.state.checkedCommodityTypes[index] = "/api/supply_commodity_types/" + this.state.checkedCommodityTypes[index]
            }
        }

        for (const index in this.state.checkedSupplyTypes) {
            this.state.checkedSupplyTypes[index] = "/api/supply_types/" + this.state.checkedSupplyTypes[index]
        }

        this.state.checkedCommodityTypes = this.state.checkedCommodityTypes.filter(id => id !== undefined)

        const validationErrors = await this.validateForm()

        if (0 !== validationErrors.length) {
            validationErrors.forEach(
                function (error) {
                    toast.error(error, {autoClose: 3500})
                },
            )

            return null
        }

        RestManager.post(Constants.REGISTER_SUPPLIER_ENDPOINT,
            {
                "name": this.state.name,
                "vatNumber": this.state.vatNumber,
                "email": this.state.email,
                "notes": this.state.notes,
                "isRegistered": false,
                "supplyTypes": this.state.checkedSupplyTypes,
                "supplyCommodityTypes": this.state.checkedCommodityTypes,
            }).then(response => {
                toast.success("Grazie, riceverai una mail per confermare l'iscrizione")

                setTimeout(() => {
                    navigate("/app/login")
                }, 3500)
            },
        ).catch(errors => {
            console.log(errors)
        })
    }

    toggleRegister(e, {checked}) {
        this.setState({
            disableRegister: !checked,
        })
    }

    render() {
        return (
            <>
                <Container as={"h2"} textAlign={"center"}>
                    <Icon name={"user outline"} size={"large"}/>
                    Registrazione nuovo fornitore
                </Container>
                <HelpDocuments/>
                <Container textAlign={"left"}>
                    <Form
                        onSubmit={event => {
                            this.handleSubmit(event)
                        }}
                    >
                        <Form.Field>
                            <label htmlFor="name">Ragione sociale</label>
                            <Input id="name"
                                   name="name"
                                   type="text"
                                   placeholder="Ragione sociale"
                                   onChange={this.handleUpdate}/>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="vatNumber">Partita IVA</label>
                            <Input id="vatNumber"
                                   name="vatNumber"
                                   type="text"
                                   placeholder="Partita IVA"
                                   onChange={this.handleUpdate}/>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="email">Email aziendale</label>
                            <Input
                                placeholder="Email aziendale"
                                id="email"
                                name="email"
                                type="text"
                                onChange={this.handleUpdate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="notes">Note</label>
                            <TextArea
                                placeholder="Note"
                                id="notes"
                                name="notes"
                                onChange={this.handleUpdate}
                            />
                        </Form.Field>
                        <Form.Group inline>
                            <label htmlFor="type1">Tipologia di forniture</label>
                            <Form.Field
                                label="Beni"
                                control="input"
                                type="checkbox"
                                onChange={this.handleUpdate}
                                id="type1"
                                name="type1"
                                checked={this.state.type1}
                            />
                            <Form.Field
                                label="Servizi"
                                control="input"
                                type="checkbox"
                                onChange={this.handleUpdate}
                                id="type2"
                                name="type2"
                                checked={this.state.type2}
                            />
                            <Form.Field
                                label="Lavori"
                                control="input"
                                type="checkbox"
                                onChange={this.handleUpdate}
                                id="type3"
                                name="type3"
                                checked={this.state.type3}
                            />
                            <Form.Field
                                label="Consulenze professionali"
                                control="input"
                                type="checkbox"
                                onChange={this.handleUpdate}
                                id="type4"
                                name="type4"
                                checked={this.state.type4}
                            />
                            <Form.Field
                                label="Lavori Cantieri Temporanei o Mobili"
                                control="input"
                                type="checkbox"
                                onChange={this.handleUpdate}
                                id="type5"
                                name="type5"
                                checked={this.state.type5}
                            />
                        </Form.Group>

                        <p>
                            {this.state.isFetching ? "Caricamento..." : ""}
                        </p>

                        <CommodityType data={this.state.supplyTypes}
                                       types={[this.state.type1, this.state.type2, this.state.type3, this.state.type4, this.state.type5]}
                                       handleUpdate={this.handleUpdate} checked={this.state.checkedCommodityTypes}/>

                        <Message>
                            <Message.Header>Attenzione</Message.Header>
                            Prima di procedere alla registrazione è necessario prendere visione della documentazione:
                            <List>
                                <List.Item>
                                    <Icon name="file alternate outline"/>
                                    <List.Content>
                                        <a href={doc1}>Informativa GDPR Albo Fornitori</a>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <Icon name="file alternate outline"/>
                                    <List.Content>
                                        <a href={doc2}>Regolamento albo fornitori</a>
                                    </List.Content>
                                </List.Item>
                            </List>
                            <Checkbox
                                label="Ho preso visione"
                                checked={!this.state.disableRegister}
                                onChange={this.toggleRegister}
                            />
                        </Message>
                        <Button
                            type="submit"
                            disabled={this.state.disableRegister}
                            color="orange"
                        >
                            Invia
                        </Button>
                    </Form>
                </Container>
            </>
        )
    }
}

export default Register