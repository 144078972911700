import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/Shared/PrivateRoute"
import MasksList from "../components/Masks/MasksList"
import CurrentDocumentStatus from "../components/Masks/CurrentDocumentStatus"
import MaskEdit from "../components/Masks/MaskEdit"
import Profile from "../components/Profile"
import LoginPage from "../components/Login"
import Home from "../components/Home"
import Register from "../components/Register"
import SetRegistrationPassword from "../components/SetRegistrationPassword"
import RetrievePassword from "../components/RetrievePassword"
import RetrievePasswordChange from "../components/RetrievePasswordChange"
import UploadedMasksList from "../components/Masks/UploadedMasksList"
import "react-toastify/dist/ReactToastify.css"

const App = () => (
  <Layout>
    <Router basepath={__PATH_PREFIX__}>
      <PrivateRoute path="/app/home" component={Home}/>
      <PrivateRoute path="/app/masksList" component={MasksList}/>
      <PrivateRoute path="/app/documentStatus" component={CurrentDocumentStatus}/>
      <PrivateRoute path="/app/maskEdit/:maskId" component={MaskEdit}/>
      <PrivateRoute path="/app/profile" component={Profile}/>
      <PrivateRoute path="/app/uploaded/documents/list" component={UploadedMasksList}/>
      <LoginPage path="/app/login"/>
      <Register path="/app/register"/>
      <RetrievePasswordChange path="/app/retrieve/password/change/:tokenId" component={RetrievePassword}/>
      <RetrievePassword path="/app/retrieve/password" component={RetrievePassword}/>
      <SetRegistrationPassword path="/app/setPassword/:username" component={SetRegistrationPassword}/>
    </Router>
  </Layout>
)

export default App