import React from "react"
import RestManager from "./Shared/RestManager"
import { Container, Form, Button, Icon } from "semantic-ui-react"
import Constants from "./Shared/Constants"
import { navigate } from "gatsby-link"
import { toast } from "react-toastify"

class RetrievePasswordChange extends React.Component {
  state = {
    newPassword: "",
    confirmNewPassword: "",
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validateForm = () => {
    const { newPassword, confirmNewPassword } = this.state

    if (confirmNewPassword !== newPassword) {
      toast.error("Le password non coincidono")
      return false
    }

    return true
  }

  handleSubmit = event => {
    event.preventDefault()

    if (this.validateForm()) {
      RestManager.post(Constants.RESET_PASSWORD_ENDPOINT,
        {
          "newPassword": this.state.newPassword,
          "tokenId": this.props.tokenId,
        })
        .then(response => {

          if (response.data.status === 200) {
            toast.success(response.data.message)
          } else {
            toast.error(response.data.message)
          }

          setTimeout(() => {
            navigate("/app/login")
          }, 3500)

        }).catch(errors => {
        console.log(errors)
      })
    }
  }

  render () {
    return (
      <>
        <Container as={"h2"} textAlign={"center"}>
          <Icon name={"user outline"} size={"large"}/>
          Reimpostazione password
        </Container>
        <Container textAlign={"left"}>Imposta la nuova password</Container>
        <Container textAlign={"left"}>
          <Form
            onSubmit={event => {
              this.handleSubmit(event)
            }}
          >
            <Form.Field>
              <label htmlFor="newPassword">Nuova password</label>
              <input id="newPassword"
                     name="newPassword"
                     type="password"
                     placeholder="Password"
                     onChange={this.handleUpdate}/>
            </Form.Field>
            <Form.Field>
              <label htmlFor="confirmNewPassword">Ripeti nuova password</label>
              <input id="confirmNewPassword"
                     name="confirmNewPassword"
                     type="password"
                     placeholder="Nuova password"
                     onChange={this.handleUpdate}/>
            </Form.Field>
            <Button type='submit'>Invia</Button>
          </Form>
        </Container>
      </>
    )
  }
}

export default RetrievePasswordChange