import React from "react"
import RestManager from "../Shared/RestManager"
import Constants from "../Shared/Constants"
import { Container, Icon } from "semantic-ui-react"
import UploadedMasksTable from "./UploadedMasksTable"
import LocalStorageManager from "../Shared/LocalStorageManager"

class UploadedMasksList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isFetching: false,
      masks: [],
    }

    this.fetchUploadedMasks = this.fetchUploadedMasks.bind(this)
  }

  componentDidMount () {
    this.fetchUploadedMasks()
  }

  fetchUploadedMasks () {
    this.setState({ ...this.state, isFetching: true })
    const username = LocalStorageManager.getKey("username")
    let masksListUrl = Constants.SUPPLIER_UPLOADED_DOCUMENTS_LIST_ENDPOINT
    masksListUrl += "?supplier.email=" + username

    RestManager.get(masksListUrl, null)
      .then(result => {
        this.setState({ masks: result.data })
        this.setState({ ...this.state, isFetching: false })
      }).catch(error => {
      console.log(error)
      this.setState({ ...this.state, isFetching: false })
    })
  }

  render () {
    return (
      <>
        <Container as={"h2"} textAlign={"center"}>
          <Icon name={"file alternate outline"} size={"large"}/>
          Documenti caricati
        </Container>
        {this.state.masks.length !== 0 ?
          <Container>
            {this.state.isFetching ?
              "Caricamento..." :
              <UploadedMasksTable
                data={this.state.masks}
                fetchUploadedMasks={() => this.fetchUploadedMasks()}
              />}
          </Container> :
          <Container textAlign={"center"}>Nessun documento caricato</Container>
        }
      </>
    )
  }

}

export default UploadedMasksList